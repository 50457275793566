<script>
import axios from "axios";
import { SimpleBar } from "simplebar-vue3";
import Swal from "sweetalert2";
import { authComputed } from "@/state/helpers";
import Cookies from "js-cookie";

/**
 * Nav-bar Component
 */
export default {
  data() {
    return {
      interval: null,
      lang: "en",
      text: null,
      flag: null,
      value: null,
      languages: [
        {
          flag: require("@/assets/images/flags/us.jpg"),
          language: "en",
          title: "English",
        },
        {
          flag: require("@/assets/images/flags/french.jpg"),
          language: "fr",
          title: "French",
        },
        {
          flag: require("@/assets/images/flags/spain.jpg"),
          language: "es",
          title: "Spanish",
        },
        {
          flag: require("@/assets/images/flags/chaina.png"),
          language: "zh",
          title: "Chinese",
        },
        {
          flag: require("@/assets/images/flags/arabic.png"),
          language: "ar",
          title: "Arabic",
        },
      ],

      navbarUser: {
        displayName: Cookies.get("session_nama"),
      },
      displayTitleApplication: Cookies.get("TITLE_APPLICATION"),
      sessionId: Cookies.get("session_id"),
      getToday: '',

      // data filter
      departement: "",
      status: "",
      sifat: "",
      bentuk: "",
      kategori: [],
      kategori_selected: "",
      tanggal_surat: "",
      tipe: "",
      role_slug: Cookies.get("session_role_slug"),
      total_inbox: 0,
      total_draft: 0,
      search: "",
    };
  },
  components: { SimpleBar },
  created() {
    this.interval = setInterval(() => {
      this.cekToken()
    },
      600000)
  },
  mounted() {
    let arrbulan = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];
    let arrhari = ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu"];
    var date = new Date();
    var hari = date.getDay();
    var tanggal = date.getDate();
    var bulan = date.getMonth();
    var tahun = date.getFullYear();
    this.getToday = arrhari[hari] + ', ' + tanggal + " " + arrbulan[bulan] + " " + tahun;

    this.getDataTable();
    this.cekToken();
  },
  methods: {
    cekToken() {
      axios
        .get(process.env.VUE_APP_BACKEND_URL_VERSION + "api/account/token/" + this.sessionId)
        .then(() => {
        })
        .catch(() => {
          let timerInterval;
          Swal.fire({
            icon: "error",
            title: "Gagal",
            text: "Sesi anda telah berakhir",
            timer: 4000,
            timerProgressBar: true,
            showCancelButton: false,
            showConfirmButton: false,
            willClose: () => {
              clearInterval(timerInterval);
            },
          }).then((result) => {
            if (result.dismiss === Swal.DismissReason.timer) {
              Swal.close();
              this.$router.push({ name: "logout" });
            }
          });
        });
    },
    getDataTable() {
      let self = this;
      self.loadingTable = true;

      var kategori_id = self.kategori_selected?.id;
      if (kategori_id) {
        kategori_id = self.kategori_selected?.id;
      } else {
        kategori_id = '';
      }

      axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "api/arsip-dokumen/suratmasuk?role_slug=" +
          self.role_slug +
          "&departement=" +
          Cookies.get("session_departemen") +
          "&jabatan=" +
          Cookies.get("session_jabatan") +
          "&id_user=" +
          Cookies.get("session_id") +
          "&status=" +
          self.status +
          "&sifat=" +
          self.sifat +
          "&bentuk=" +
          self.bentuk +
          "&kategori=" +
          kategori_id +
          "&tanggal_surat=" +
          self.tanggal_surat +
          "&tipe=" +
          self.tipe +
          "&search=" +
          self.search
        )
        .then((response) => {
          var response_data = response.data;
          if (response_data.code == 200) {
            self.total_inbox = response_data.count_inbox;
          }
        });
    },
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },

    logoutUser() {
      // eslint-disable-next-line no-unused-vars
      axios.get("http://127.0.0.1:8000/api/logout").then((res) => {
        this.$router.push({
          name: "default",
        });
      });
    },
  },
  computed: {
    ...authComputed,
  },
};
</script>

<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div
          class="navbar-brand-box"
          style="background-color: #00529c"
        >
          <router-link
            to="/"
            class="logo logo-dark"
          >
            <span class="logo-sm">
              <img
                src="@/assets/images/logo.svg"
                alt
                height="22"
              />
            </span>
            <span class="logo-lg">
              <img
                src="@/assets/images/logo-dark.png"
                alt
                height="17"
              />
            </span>
          </router-link>

          <router-link
            to="/"
            class="logo logo-light"
          >
            <span class="logo-sm">
              <img
                src="@/assets/images/login_logo.jpg"
                alt
                height="10"
              />
            </span>
            <span class="logo-lg">
              <!-- <img src="@/assets/images/logo-light.png" alt height="19" /> -->
              <h3
                class="text-white mt-4"
                style="font-size: 22px !important; "
              >{{ displayTitleApplication }}</h3>
            </span>
          </router-link>
        </div>

        <button
          id="vertical-menu-btn"
          type="button"
          class="btn btn-sm px-3 font-size-16 header-item"
          @click="toggleMenu"
        >
          <i class="fa fa-fw fa-bars"></i>
        </button>

        <!-- App Search-->
        <!-- <form class="app-search d-none d-lg-block">
          <div class="position-relative">
            <input
              type="text"
              class="form-control"
              :placeholder="$t('navbar.search.text')"
            />
            <span class="bx bx-search-alt"></span>
          </div>
        </form>
         -->
        <!-- <b-dropdown
          variant="black"
          class="dropdown-mega d-none d-lg-block ms-2"
          toggle-class="header-item"
          menu-class="dropdown-megamenu"
        >
          <template v-slot:button-content>
            {{ $t("navbar.dropdown.megamenu.text") }}
            <i class="mdi mdi-chevron-down"></i>
          </template>

<div class="row">
  <div class="col-sm-8">
    <div class="row">
      <div class="col-md-4">
        <h5 class="font-size-14 mt-0">
          {{ $t("navbar.dropdown.megamenu.uicontent.title") }}
        </h5>
        <ul class="list-unstyled megamenu-list">
          <li>
            <a href="javascript:void(0);">{{
              $t("navbar.dropdown.megamenu.uicontent.list.lightbox")
              }}</a>
          </li>
          <li>
            <a href="javascript:void(0);">{{
              $t(
              "navbar.dropdown.megamenu.uicontent.list.rangeslider"
              )
              }}</a>
          </li>
          <li>
            <a href="javascript:void(0);">{{
              $t("navbar.dropdown.megamenu.uicontent.list.sweetalert")
              }}</a>
          </li>
          <li>
            <a href="javascript:void(0);">{{
              $t("navbar.dropdown.megamenu.uicontent.list.rating")
              }}</a>
          </li>
          <li>
            <a href="javascript:void(0);">{{
              $t("navbar.dropdown.megamenu.uicontent.list.forms")
              }}</a>
          </li>
          <li>
            <a href="javascript:void(0);">{{
              $t("navbar.dropdown.megamenu.uicontent.list.tables")
              }}</a>
          </li>
          <li>
            <a href="javascript:void(0);">{{
              $t("navbar.dropdown.megamenu.uicontent.list.charts")
              }}</a>
          </li>
        </ul>
      </div>

      <div class="col-md-4">
        <h5 class="font-size-14 mt-0">
          {{ $t("navbar.dropdown.megamenu.application.title") }}
        </h5>
        <ul class="list-unstyled megamenu-list">
          <li>
            <a href="javascript:void(0);">{{
              $t(
              "navbar.dropdown.megamenu.application.list.ecommerce"
              )
              }}</a>
          </li>
          <li>
            <a href="javascript:void(0);">{{
              $t("navbar.dropdown.megamenu.application.list.calendar")
              }}</a>
          </li>
          <li>
            <a href="javascript:void(0);">{{
              $t("navbar.dropdown.megamenu.application.list.email")
              }}</a>
          </li>
          <li>
            <a href="javascript:void(0);">{{
              $t("navbar.dropdown.megamenu.application.list.projects")
              }}</a>
          </li>
          <li>
            <a href="javascript:void(0);">{{
              $t("navbar.dropdown.megamenu.application.list.tasks")
              }}</a>
          </li>
          <li>
            <a href="javascript:void(0);">{{
              $t("navbar.dropdown.megamenu.application.list.contacts")
              }}</a>
          </li>
        </ul>
      </div>

      <div class="col-md-4">
        <h5 class="font-size-14 mt-0">
          {{ $t("navbar.dropdown.megamenu.extrapages.title") }}
        </h5>
        <ul class="list-unstyled megamenu-list">
          <li>
            <a href="javascript:void(0);">{{
              $t(
              "navbar.dropdown.megamenu.extrapages.list.lightsidebar"
              )
              }}</a>
          </li>
          <li>
            <a href="javascript:void(0);">{{
              $t(
              "navbar.dropdown.megamenu.extrapages.list.compactsidebar"
              )
              }}</a>
          </li>
          <li>
            <a href="javascript:void(0);">{{
              $t(
              "navbar.dropdown.megamenu.extrapages.list.horizontallayout"
              )
              }}</a>
          </li>
          <li>
            <a href="javascript:void(0);">{{
              $t(
              "navbar.dropdown.megamenu.extrapages.list.maintenance"
              )
              }}</a>
          </li>
          <li>
            <a href="javascript:void(0);">{{
              $t(
              "navbar.dropdown.megamenu.extrapages.list.comingsoon"
              )
              }}</a>
          </li>
          <li>
            <a href="javascript:void(0);">{{
              $t("navbar.dropdown.megamenu.extrapages.list.timeline")
              }}</a>
          </li>
          <li>
            <a href="javascript:void(0);">{{
              $t("navbar.dropdown.megamenu.extrapages.list.faqs")
              }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="col-sm-4">
    <div class="row">
      <div class="col-sm-6">
        <h5 class="font-size-14 mt-0">
          {{ $t("navbar.dropdown.megamenu.uicontent.title") }}
        </h5>
        <ul class="list-unstyled megamenu-list">
          <li>
            <a href="javascript:void(0);">{{
              $t("navbar.dropdown.megamenu.uicontent.list.lightbox")
              }}</a>
          </li>
          <li>
            <a href="javascript:void(0);">{{
              $t(
              "navbar.dropdown.megamenu.uicontent.list.rangeslider"
              )
              }}</a>
          </li>
          <li>
            <a href="javascript:void(0);">{{
              $t("navbar.dropdown.megamenu.uicontent.list.sweetalert")
              }}</a>
          </li>
          <li>
            <a href="javascript:void(0);">{{
              $t("navbar.dropdown.megamenu.uicontent.list.rating")
              }}</a>
          </li>
          <li>
            <a href="javascript:void(0);">{{
              $t("navbar.dropdown.megamenu.uicontent.list.forms")
              }}</a>
          </li>
          <li>
            <a href="javascript:void(0);">{{
              $t("navbar.dropdown.megamenu.uicontent.list.tables")
              }}</a>
          </li>
          <li>
            <a href="javascript:void(0);">{{
              $t("navbar.dropdown.megamenu.uicontent.list.charts")
              }}</a>
          </li>
        </ul>
      </div>

      <div class="col-sm-5">
        <div>
          <img src="@/assets/images/megamenu-img.png" alt class="img-fluid mx-auto d-block" />
        </div>
      </div>
    </div>
  </div>
</div>
</b-dropdown> -->
      </div>

      <div class="d-flex">
        <!-- <b-dropdown
              class="d-inline-block d-lg-none ms-2"
              variant="black"
              menu-class="dropdown-menu-lg p-0"
              toggle-class="header-item noti-icon"
              right
            >
              <template v-slot:button-content>
                <i class="mdi mdi-magnify"></i>
              </template>

              <form class="p-3">
                <div class="form-group m-0">
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search ..."
                      aria-label="Recipient's username"
                    />
                    <div class="input-group-append">
                      <button class="btn btn-primary" type="submit">
                        <i class="mdi mdi-magnify"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </b-dropdown> -->

        <!-- <select v-model="$i18n.locale" class="lang-dropdown border-0">
              <option v-for="(locale, i) in languages" :key="`locale-${i}`" :value="locale.language">
                {{ locale.title }}
              </option>
            </select> -->

        <!-- <b-dropdown
              class="d-none d-lg-inline-block noti-icon"
              menu-class="dropdown-menu-lg dropdown-menu-end"
              right
              toggle-class="header-item"
              variant="black"
            >
              <template v-slot:button-content>
                <i class="bx bx-customize"></i>
              </template>

              <div class="px-lg-2">
                <div class="row no-gutters">
                  <div class="col">
                    <a class="dropdown-icon-item" href="javascript: void(0);">
                      <img src="@/assets/images/brands/github.png" alt="Github" />
                      <span>{{ $t("navbar.dropdown.site.list.github") }}</span>
                    </a>
                  </div>
                  <div class="col">
                    <a class="dropdown-icon-item" href="javascript: void(0);">
                      <img
                        src="@/assets/images/brands/bitbucket.png"
                        alt="bitbucket"
                      />
                      <span>{{ $t("navbar.dropdown.site.list.github") }}</span>
                    </a>
                  </div>
                  <div class="col">
                    <a class="dropdown-icon-item" href="javascript: void(0);">
                      <img
                        src="@/assets/images/brands/dribbble.png"
                        alt="dribbble"
                      />
                      <span>{{ $t("navbar.dropdown.site.list.dribbble") }}</span>
                    </a>
                  </div>
                </div>

                <div class="row no-gutters">
                  <div class="col">
                    <a class="dropdown-icon-item" href="javascript: void(0);">
                      <img src="@/assets/images/brands/dropbox.png" alt="dropbox" />
                      <span>{{ $t("navbar.dropdown.site.list.dropbox") }}</span>
                    </a>
                  </div>
                  <div class="col">
                    <a class="dropdown-icon-item" href="javascript: void(0);">
                      <img
                        src="@/assets/images/brands/mail_chimp.png"
                        alt="mail_chimp"
                      />
                      <span>{{ $t("navbar.dropdown.site.list.mailchimp") }}</span>
                    </a>
                  </div>
                  <div class="col">
                    <a class="dropdown-icon-item" href="javascript: void(0);">
                      <img src="@/assets/images/brands/slack.png" alt="slack" />
                      <span>{{ $t("navbar.dropdown.site.list.slack") }}</span>
                    </a>
                  </div>
                </div>
              </div>
            </b-dropdown> -->

        <!-- <div class="dropdown d-none d-lg-inline-block ms-1">
          <button
                type="button"
                class="btn header-item noti-icon"
                @click="initFullScreen"
              >
                <i class="bx bx-fullscreen"></i>
              </button>
            </div> -->
        <!-- <div class="dropdown d-inline-block">
              <button
                type="button"
                class="btn header-item noti-icon right-bar-toggle toggle-right"
                @click="toggleRightSidebar"
              >
            <i class="bx bx-cog bx-spin toggle-right"></i>
          </button>
            </div> -->
        <div class="dropdown d-inline-block">
          <button
            type="button"
            class="btn header-item noti-icon right-bar-toggle toggle-right"
          >
            <i
              class="font-size-13"
              style="font-size: 14.5px !important;"
            >{{ getToday }}</i>
          </button>
        </div>
        <b-dropdown
          v-if="role_slug == 'super_admin'"
          right
          menu-class="dropdown-menu-md p-0 dropdown-menu-end"
          toggle-class="header-item noti-icon"
          variant="black"
        >
          <template v-slot:button-content>
            <i class="bx bx-wrench"></i>
          </template>
          <b-dropdown-item>
            <router-link :to="{ name: 'all-jabatan' }">
              <span
                @click="navigate"
                @keypress.enter="navigate"
                class="text-black"
              >
                <i class="bx bx-id-card font-size-16 align-middle"></i>
                Jabatan
              </span>
            </router-link>
          </b-dropdown-item>
          <b-dropdown-item>
            <router-link :to="{ name: 'all-kategori_surat' }">
              <span
                @click="navigate"
                @keypress.enter="navigate"
                class="text-black"
              >
                <i class="bx bx-bookmark font-size-16 align-middle"></i>
                Kategori Surat
              </span>
            </router-link>
          </b-dropdown-item>
          <b-dropdown-item>
            <router-link :to="{ name: 'edit-konfigurasi' }">
              <span
                @click="navigate"
                @keypress.enter="navigate"
                class="text-black"
              >
                <i class="bx bx-wrench font-size-16 align-middle"></i>
                Konfigurasi
              </span>
            </router-link>
          </b-dropdown-item>
          <b-dropdown-item>
            <router-link :to="{ name: 'all-konfigurasi_ekstensi' }">
              <span
                @click="navigate"
                @keypress.enter="navigate"
                class="text-black"
              >
                <i class="bx bx-paperclip font-size-16 align-middle"></i>
                Konfigurasi Ekstensi
              </span>
            </router-link>
          </b-dropdown-item>
          <b-dropdown-item>
            <router-link :to="{ name: 'all-tipe-surat-keluar' }">
              <span
                @click="navigate"
                @keypress.enter="navigate"
                class="text-black"
              >
                <i class="bx bx-chevrons-right font-size-16 align-middle"></i>
                Tipe Surat Keluar
              </span>
            </router-link>
          </b-dropdown-item>
          <b-dropdown-item>
            <router-link :to="{ name: 'all-user' }">
              <span
                @click="navigate"
                @keypress.enter="navigate"
                class="text-black"
              >
                <i class="bx bx-user font-size-16 align-middle"></i>
                User
              </span>
            </router-link>
          </b-dropdown-item>
          <b-dropdown-item>
            <router-link :to="{ name: 'all-klasifikasi' }">
              <span
                @click="navigate"
                @keypress.enter="navigate"
                class="text-black"
              >
                <i class="bx bx-list-ul font-size-16 align-middle"></i>
                Kategori Klasifikasi
              </span>
            </router-link>
          </b-dropdown-item>
          <b-dropdown-item>
            <router-link :to="{ name: 'all-lingkup' }">
              <span
                @click="navigate"
                @keypress.enter="navigate"
                class="text-black"
              >
                <i class="bx bx-radar font-size-16 align-middle"></i>
                Lingkup Kegiatan
              </span>
            </router-link>
          </b-dropdown-item>
          <b-dropdown-item>
            <router-link :to="{ name: 'all-kantor' }">
              <span
                @click="navigate"
                @keypress.enter="navigate"
                class="text-black"
              >
                <i class="bx bx-building font-size-16 align-middle"></i>
                Kantor
              </span>
            </router-link>
          </b-dropdown-item>
          <b-dropdown-item>
            <router-link :to="{ name: 'all-keuntungan' }">
              <span
                @click="navigate"
                @keypress.enter="navigate"
                class="text-black"
              >
                <i class="bx bx-money font-size-16 align-middle"></i>
                Keuntungan
              </span>
            </router-link>
          </b-dropdown-item>
          <b-dropdown-item>
            <router-link :to="{ name: 'all-tipe_vendor' }">
              <span
                @click="navigate"
                @keypress.enter="navigate"
                class="text-black"
              >
                <i class="bx bx-health font-size-16 align-middle"></i>
                Tipe Vendor/Konsultan
              </span>
            </router-link>
          </b-dropdown-item>
          <b-dropdown-item>
            <router-link :to="{ name: 'all-departemen' }">
              <span
                @click="navigate"
                @keypress.enter="navigate"
                class="text-black"
              >
                <i class="bx bx-building-house font-size-16 align-middle"></i>
                Departemen
              </span>
            </router-link>
          </b-dropdown-item>
          <b-dropdown-item>
            <router-link :to="{ name: 'all-breakdown' }">
              <span
                @click="navigate"
                @keypress.enter="navigate"
                class="text-black"
              >
                <i class="bx bx-credit-card font-size-16 align-middle"></i>
                Breakdown
              </span>
            </router-link>
          </b-dropdown-item>
          <b-dropdown-item>
            <router-link :to="{ name: 'all-posisi' }">
              <span
                @click="navigate"
                @keypress.enter="navigate"
                class="text-black"
              >
                <i class="bx bx-user-check font-size-16 align-middle"></i>
                Posisi
              </span>
            </router-link>
          </b-dropdown-item>
          <b-dropdown-item>
            <router-link :to="{ name: 'all-posisi_jabatan' }">
              <span
                @click="navigate"
                @keypress.enter="navigate"
                class="text-black"
              >
                <i class="bx bx-anchor font-size-16 align-middle"></i>
                Posisi Jabatan
              </span>
            </router-link>
          </b-dropdown-item>
          <b-dropdown-item>
            <router-link :to="{ name: 'all-divisi' }">
              <span
                @click="navigate"
                @keypress.enter="navigate"
                class="text-black"
              >
                <i class="bx bx-female font-size-16 align-middle"></i>
                Divisi
              </span>
            </router-link>
          </b-dropdown-item>
          <b-dropdown-item>
            <router-link :to="{ name: 'all-komite' }">
              <span
                @click="navigate"
                @keypress.enter="navigate"
                class="text-black"
              >
                <i class="bx bx-id-card font-size-16 align-middle"></i>
                Komite
              </span>
            </router-link>
          </b-dropdown-item>
        </b-dropdown>

        <b-dropdown
          right
          menu-class="dropdown-menu-lg p-0 dropdown-menu-end"
          toggle-class="header-item noti-icon"
          variant="black"
        >
          <template v-slot:button-content>
            <i class="bx bx-envelope"></i>
            <span class="badge bg-danger rounded-pill">{{ total_inbox }}</span>
          </template>

          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">
                  {{ $t("navbar.dropdown.notification.text") }}
                </h6>
              </div>
              <div class="col-auto">
                <!-- <a href="#" class="small">
                      {{
                      $t("navbar.dropdown.notification.subtext")
                    }}</a> -->
              </div>
            </div>
          </div>
          <SimpleBar style="max-height: 230px">
            <a
              href="javascript: void(0);"
              class="text-reset notification-item"
            >
              <div class="d-flex">
                <div class="avatar-xs me-1">
                  <span class="avatar-title bg-primary rounded-circle font-size-16">
                    <i class="bx bx-envelope"></i>
                  </span>
                </div>
                <div class="flex-grow-1">
                  <!-- <h6 class="mt-0 mb-1">
                        {{ $t("navbar.dropdown.notification.order.title") }}
                      </h6> -->
                  <!-- <div class="font-size-12 text-muted">
                        <p class="mb-1">
                          {{ $t("navbar.dropdown.notification.notif.text") }}
                        </p>
                        <p class="mb-0">
                          <i class="mdi mdi-clock-outline"></i>
                          {{ $t("navbar.dropdown.notification.order.time") }}
                        </p>
                      </div>
                      <h6 class="mt-0 mb-1">
                        {{ $t("navbar.dropdown.notification.order.title") }}
                      </h6> -->
                  <router-link
                    :to="{ name: 'surat-masuk-inbox' }"
                    class="text-reset notification-item"
                  >
                    <div class="font-size-12 text-muted">
                      <p class="mb-1">

                        Anda memiliki {{ total_inbox }} pesan baru
                      </p>
                    </div>
                  </router-link>
                </div>
              </div>
            </a>
          </SimpleBar>
        </b-dropdown>

        <b-dropdown
          right
          variant="black"
          toggle-class="header-item"
          menu-class="dropdown-menu-end"
        >
          <template v-slot:button-content>
            <img
              class="rounded-circle header-profile-user"
              src="@/assets/images/users/avatar-1.jpg"
              alt="Header Avatar"
            />
            <span class="d-none d-xl-inline-block ms-1">
              <div v-if="navbarUser">
                {{ navbarUser.displayName }}
              </div>
              <div v-else>Smartsoft Studio</div>
            </span>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </template>
          <!-- item-->
          <b-dropdown-item>
            <router-link :to="{ name: 'profil' }">
              <span
                @click="navigate"
                @keypress.enter="navigate"
              >
                <i class="bx bx-user font-size-16 align-middle me-1"></i>
                {{ $t("navbar.dropdown.henry.list.profile") }}
              </span>
            </router-link>
          </b-dropdown-item>
          <b-dropdown-item>
            <router-link :to="{ name: 'lockscreen' }">
              <span
                @click="navigate"
                @keypress.enter="navigate"
              >
                <i class="bx bx-lock-open font-size-16 align-middle me-1"></i>
                {{ $t("navbar.dropdown.henry.list.lockscreen") }}
              </span>
            </router-link>
          </b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <router-link
            to="/logout"
            class="dropdown-item text-danger"
          >
            <i class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
            {{ $t("navbar.dropdown.henry.list.logout") }}
          </router-link>
        </b-dropdown>

        <!-- <div class="dropdown d-inline-block">
              <button
                type="button"
                class="btn header-item noti-icon right-bar-toggle toggle-right"
                @click="toggleRightSidebar"
              >
                <i class="bx bx-cog bx-spin toggle-right"></i>
              </button>
            </div> -->
      </div>
    </div>
  </header>
</template>
